<template>
  <div class="director content_area">
    <div class="title">
      理事单位权益
    </div>
    <p>
      <span>
         一、全体会员单位权益
    </span>
      （包括并不限于以下项目）：
    </p>
    <p>
      （一）保障会员享有协会工作的选举权、被选举权、表决权等权利，及对协会工作的知情权、建议权和监督权；
    </p>
    <p>
      （二）每年以书面形式向会员汇报年度工作、财务状况及重大事项；
    </p>
    <p>
      （三）提供一对一咨询服务，解答通关过程中遇到的疑难杂症，解答其他疑难问题的渠道，如将意见和建反馈至政府相关部门；
    </p>
    <p>
      （四）享有行业热点和政策推送以及业务咨询服务；
    </p>
    <p>
      （五）提供本会组织的专业培训，享有协会公益课程，对协会收费培训课程享有会员价，对“公共基础类”培训课程享有一人免费培训名额。听取会员对协会培训工作（课件、讲师、培训内容等）的满意度反馈；
    </p>
    <p>
      （六）提供经营业务或产品方面的宣传；
    </p>
    <p>
      （七）提供本会组织的行业交流、行业论坛、品牌展示等活动；
    </p>
    <p>
      （八）提供职业院校和其他专业人才信息；
    </p>
    <p>
      （九）工作人员为会员提供维权服务，搭建协调和解决问题的平台，确保双方沟通顺畅，推荐优质的律师事务所，为会员单位的合法权益保驾护航；
    </p>
    <p>
      （十）工作人员需在会员困难时给予帮困服务，听取会员在关务工作中提出的建议与意见，并尽力为您解决。定期与协会领导班子走访会员进行实地调研，听取会员难处、困处，并尽力解决；
    </p>
    <p>
      （十一）向海关总署相关部门和有关海关反映会员意见、建议。
    </p>'
    <p>
      <span>
        二、理事单位权益
      </span>
      （包括并不限于以下项目）：
    </p>
    <p>
      （一）确保提供理事参与协会管理工作的决策权；
    </p>
    <p>
      （二）优先在协会网站、会刊等媒体进行形象展示；
    </p>
    <p>
      （三）提供一对一培训增值服务，提供最新培训课程通知，定期听取会员对培训课程的需求，提供一对一个性化定制培训方案。经授课老师授权，提供会员相应课件或其他培训资料；
    </p>
    <p>
      （四）提供会员一对一的信息传递服务，提醒会员协会即将开展的重大工作和活动，负责向会员推送行业专业期刊，负责向会员提供行业内权威水平评价的知情权；
    </p>
    <p>
      （五）对“公共基础类”培训课程享有两人免费培训名额；
    </p>
    <p>
      （六）优先推荐专家工作组，对优秀或示范单位相关工作享有优先推荐；
    </p>
    <p>
      （七）优先参与海关总署和上海海关组织的有关政策、调研和听证等活动。
    </p>
  </div>
</template>

<script>
export default {
  name: "director"
}
</script>

<style scoped lang="less">
.director{
  background-color: #fff;
  padding: 30px;
  .title{
    text-align: center;
    font-size: 26px;
    padding: 20px;
  }
  p{
    margin-bottom: 15px;
    font-size: 16px;
    span{
      font-weight: bold;
    }
  }
}
</style>